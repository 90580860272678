<template>
  <dialog-outline v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner
        :record="record"
        :is-history-mode="isViewFileMode"
      ></record-banner>

      <!-- Download file as option -->
      <v-switch
        :prepend-icon="downloadAsPdfIcon"
        v-model="isDownloadAsPdf"
        v-show="!hideSwitch"
        :label="downloadAsPdfLabel"
      ></v-switch>

      <!-- Download Annotation/Redaction options -->
      <download-annotation-option
        :annotate-option="annotateOption"
        :manage-annotations="isAllowedAnnotate"
        :manageRedactions="isAllowedRedact"
        v-if="visibleAnnotationOption"
      >
      </download-annotation-option>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!--  Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk">{{ dialogOkCommandLabel }}</base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// services
import {
  fullRecordVersionName,
  isAllowedOperation,
  isAncestorMeeting,
  IsCheckedOutForDigitalSigningByPrinciple,
  isCompoundDocument,
  localFileFirstOrDefaultExtension
} from "@/services/record/recordService";

// model
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";
import { fieldDownloadType } from "@/model/document/documentModel";
import {
  downloadFileMode,
  extensionName,
  findExtension,
  isAnnotateFileTypeExtension,
  isPdfExtension
} from "@/model/record/fileModel";
import { actionResultType } from "@/model/action/actionModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";
import { downloadableMixin } from "@/mixins/shared/downloadable/downloadableMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

export default {
  name: "DownloadFileDialog",
  mixins: [
    dialogOutlineMixin,
    annotationOptionMixin,
    downloadableMixin,
    progressAbleMixin,
    recordBannerAble
  ],
  data() {
    return {
      isDownloadAsPdf: false,
      downloadFileMode: downloadFileMode
    };
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    downloadMode: {
      type: Number,
      default: downloadFileMode.default
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return this.isEmail
        ? findRecordOperation(recordOperation.Email)
        : findRecordOperation(recordOperation.Download);
    },

    /**
     * download operation Label
     * @return {string} download operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Download Dialog Title
     * @return {string} Formatted download Dialog Title
     */
    dialogTitle() {
      return `${this.operationLabel} ${this.record?.recordType}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordVersionName(this.record)}'`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * determines whether current user is Allowed to Annotate current image
     * @return {Boolean|boolean} return true if current user is allowed to Annotate current image
     */
    isAllowedAnnotate() {
      return this.isAllowedOperation(recordOperation.RunImageAnnotation);
    },

    /**
     * determines whether current user is Allowed to Redact current image
     * @return {Boolean|boolean} return true if current user is allowed to Redact current image
     */
    isAllowedRedact() {
      return this.isAllowedOperation(recordOperation.RunImageRedaction);
    },

    /**
     * compute download As Pdf Label
     * @return {string} download As Pdf Label
     */
    downloadAsPdfLabel() {
      return `As ${findExtension(extensionName.pdf)?.acronym}`;
    },

    /**
     * download As Pdf Icon name
     * @return {string} download As Pdf Icon name
     */
    downloadAsPdfIcon() {
      return findExtension(extensionName.pdf)?.icon;
    },

    /**
     * Is WIP File
     * @return {boolean}
     */
    isWip() {
      return this.downloadMode === downloadFileMode.wip;
    },

    /**
     * visible Annotation Option
     * @return {boolean}
     */
    visibleAnnotationOption() {
      if (this.isWip) {
        return true;
      }

      if (isAncestorMeeting(this.record) || this.isPdf) {
        return false;
      }

      if (this.isCompound) return true;

      return isAnnotateFileTypeExtension(this.record?.extension);
    },

    /**
     * Check if the record is Pdf, no need to show download as pdf switch
     * @return {boolean}
     */
    isPdf() {
      return this.isWip
        ? isPdfExtension(localFileFirstOrDefaultExtension(this.record))
        : IsCheckedOutForDigitalSigningByPrinciple(this.record, this.principal)
        ? isPdfExtension(this.record?.checkOutExtension)
        : isPdfExtension(this.record?.extension);
    },

    /**
     * Check if record is Compound Document
     * @return {boolean} true if provided record is Compound document
     */
    isCompound() {
      return isCompoundDocument(this.record);
    },

    /**
     * Check if pdf switch should be hidden
     * @return {boolean}
     */
    hideSwitch() {
      return this.isPdf || this.isCompound;
    },

    /**
     * Download Progress Text
     * @return {string}
     */
    downloadProgressText() {
      return `Downloading ${this.record?.recordType}: '${fullRecordVersionName(
        this.record
      )}' `;
    },

    /**
     * Is View File Mode (File History)
     * @return {boolean}
     */
    isViewFileMode() {
      return this.downloadMode === downloadFileMode.view;
    }
  },
  methods: {
    async onOk() {
      try {
        this.clearAlert();
        this.showProgress(this.formatProgressText(this.downloadProgressText));

        const downloadType = this.isDownloadAsPdf
          ? fieldDownloadType.asPdf
            ? isPdfExtension(this.record.checkOutExtension)
            : fieldDownloadType.asPdf
          : fieldDownloadType.asItIs;

        const downloadFileOption = {
          includeAnnotations: this.visibleAnnotationOption
            ? this.annotateOption.includeAnnotations
            : false,
          includeRedaction: this.visibleAnnotationOption
            ? this.annotateOption.includeRedaction
            : false,
          burnIntoImage: this.visibleAnnotationOption
            ? this.annotateOption.burnIntoImage
            : false,
          burnRedaction: this.visibleAnnotationOption
            ? this.annotateOption.burnRedaction
            : false,
          maintainColor: this.visibleAnnotationOption
            ? this.annotateOption.maintainColor
            : false
        };

        /**
         * TODO: use downloadFileOption sa an arg in: this.onDownloadFile()
         */
        const result = await this.onDownloadFile(
          this.record,
          downloadType,
          downloadFileOption,
          this.downloadMode,
          this.isEmail
        );

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else if (result?.type === actionResultType.abort) {
          this.closeDialog();
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.closeProgress();

        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.closeProgress();
      }
    },

    /**
     * is Allowed Operation
     * @param operation operation name
     * @return {Boolean|boolean} true if Operation is Allowed
     */
    isAllowedOperation(operation) {
      return this.record ? isAllowedOperation(this.record, operation) : false;
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;

      this.closeProgress();
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;

      this.closeProgress();
    }
  }
};
</script>
